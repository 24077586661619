<template>
	<div style="background: #fff;padding: 15px;">
		<!-- tab切换 -->
		<el-tabs v-model="listState" @tab-click="filterFun">
			<el-tab-pane v-for="item in listStateList" :key="item.id" :label="item.value" :name="item.id+''"></el-tab-pane>
		</el-tabs>
		<div v-if="listState==0">
			<div class="filter-container">
				<buttonPermissions :datas="'addPointRule'">
					<el-button type="primary" size="mini" style="width:80px;" @click="eidtGroup({})" v-if="ScoreManageData.length<3">添加</el-button>
				</buttonPermissions>
				<div style="font-size: 14px;color: #101010;margin-top: 20px;margin-bottom: 20px;">
					累计发放: 
					<span v-if="!scoreLoading && ManageSendPoint !== null" style="color: #909399;margin-right: 30px;margin-left: 5px;">{{ManageSendPoint}}</span>
					<span v-if="!scoreLoading && ManageSendPoint === null" style="color: #909399;margin-right: 30px;margin-left: 5px;"><el-link type="primary" @click="getScoreTotal">查询失败，点击重试</el-link></span>
					<span v-if="scoreLoading" style="color: #909399;margin-right: 30px;margin-left: 5px;">查询中<i class="el-icon-loading"></i></span>
					累计支出: 
					<span v-if="!scoreLoading && ManageSpendPoint !== null" style="color: #909399;margin-right: 30px;margin-left: 5px;">{{Math.abs(ManageSpendPoint)}}</span>
					<span v-if="!scoreLoading && ManageSpendPoint === null" style="color: #909399;margin-right: 30px;margin-left: 5px;"><el-link type="primary" @click="getScoreTotal">查询失败，点击重试</el-link></span>
					<span v-if="scoreLoading" style="color: #909399;margin-right: 30px;margin-left: 5px;">查询中<i class="el-icon-loading"></i></span>
					累计冻结: 
					<span v-if="!scoreLoading && ManageBlockPoint !== null" style="color: #909399;margin-right: 30px;margin-left: 5px;">{{ManageBlockPoint}}</span>
					<span v-if="!scoreLoading && ManageBlockPoint === null" style="color: #909399;margin-right: 30px;margin-left: 5px;"><el-link type="primary" @click="getScoreTotal">查询失败，点击重试</el-link></span>
					<span v-if="scoreLoading" style="color: #909399;margin-right: 30px;margin-left: 5px;">查询中<i class="el-icon-loading"></i></span>
				</div>
			</div>
			<!-- 积分管理表格 -->
			<div class="table-container">
				<el-table :data="ScoreManageData" style="width: 100%;" v-loading="loading">
					<el-table-column :key="1" prop="GivingTypeRule" label="规则"></el-table-column>
					<el-table-column :key="2" prop="Point" label="发放积分"></el-table-column>
					<el-table-column :key="3" prop="area" label="参与商品" width="200px">
						<template slot-scope="scope">
							<div>{{scope.row.JoinProductValue}}</div>
						</template>
					</el-table-column>
					<el-table-column :key="4" prop="GivingTotalPoint" label="已发放"></el-table-column>
					<el-table-column :key="5" prop="UpdateTime" label="最后编辑时间"></el-table-column>
					<el-table-column :key="6" prop="AddTime" label="创建时间"></el-table-column>
					<el-table-column :key="7" label="操作" width="140">
						<template slot-scope="scope">

							<buttonPermissions :datas="'editPointRule'">
								<el-button type="text" @click="eidtGroup(scope.row)">编辑</el-button>
							</buttonPermissions>
							<buttonPermissions :datas="'deletePointRule'">
								<el-button type="text" style="color:#f00;margin-left:15px" @click="deleteChose(scope.row)">删除</el-button>
							</buttonPermissions>

						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>

		<!-- 积分明细 -->

		<div v-else>
			<div class="filter-container">
				<div class="filter-item">
					<div>
						<label class="label">关键字:</label>
						<el-select v-model="searchType" placeholder="请选择"style="margin-right: 10px;width: 120px;">
							<el-option v-for="(item,index) in searchTypeList" :key="index" :label="item.value" :value="item.id">
							</el-option>
						</el-select>
						<el-input v-model="searchKey" @keyup.enter.native="searchHandel" :placeholder="placeholder" style="width: 200px;"></el-input>
					</div>
					<div style="margin-top: 10px;">
						<label class="label">变更类型: </label>
						<el-select v-model="ChangeState" placeholder="请选择" filterable clearable style="margin-right: 20px;">
							<el-option :value="null" label="全部"></el-option>
							<el-option v-for="(item,index) in ChangeStateList" :key="index" :label="item.Name" :value="item.Id">
							</el-option>
						</el-select>
						<label class="label">变更原因: </label>
						<el-select v-model="CauseId" placeholder="请选择" filterable clearable style="margin-right: 20px;">
							<el-option :value="null" label="全部"></el-option>
							<el-option v-for="(item,index) in CauseList" :key="item.Value" :label="item.Name" :value="item.Value">
							</el-option>
						</el-select>
						<label class="label">变更时间: </label>
						<el-date-picker @change="timeChange" v-model="timeValue" type="datetimerange" format="yyyy-MM-dd HH:mm:ss"
						 value-format="yyyy-MM-dd HH:mm:ss" range-separator="~" start-placeholder="开始时间" end-placeholder="结束时间">
						</el-date-picker>
						<el-button type="primary" @click="searchHandel" style="margin-left:20px;">查询</el-button>
					</div>
				</div>
				<div style="font-size: 14px;color: #101010;margin-top: 10px;background: #F0F2F5;padding: 15px 10px;" v-if="isSearch">
					当前积分: <span style="color: #909399;margin-right: 30px;">{{detailAllpoint}}</span>
					已发放积分: <span style="color: #909399;margin-right: 30px;">{{detailSendPoint}}</span>
					冻结积分: <span style="color: #909399;margin-right: 30px;">{{detailBlockPoint}}</span>
					已支出积分: <span style="color: #909399;margin-right: 30px;">{{detailSpendPoint}}</span>
				</div>
			</div>

			<div class="table-container">
				<el-table :data="ScoredetailData" style="width: 100%;" v-loading="detailoading">
					<el-table-column :key="8" label="客户" width="110">
						<template slot-scope="scope">
							<div v-if="scope.row.WxNickname">{{scope.row.WxNickname==''?scope.row.Name:scope.row.WxNickname}}</div>
							<div>{{scope.row.Phone}}</div>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="ChangeType" label="变更类型" :key='Math.random()'>
						<template slot-scope="scope">
							<div v-if="scope.row.ChangeType==0">收入</div>
							<div v-if="scope.row.ChangeType==1">支出</div>
							<div v-if="scope.row.ChangeType==2">冻结</div>
						</template>
					</el-table-column> -->
					<el-table-column :key="9" prop="ChangeType" label="变更类型"></el-table-column>
					<el-table-column :key="10" prop="RecordTypeValue" label="变更原因" width="200px"></el-table-column>
					<el-table-column :key="11" prop="PointChange" label="变更数量">
						<template slot-scope="scope">
							<div v-if="scope.row.PointChange<0" style="color:#F56C6C">{{scope.row.PointChange}}</div>
							<div v-else>{{scope.row.PointChange}}</div>
						</template>
					</el-table-column>
					<el-table-column :key="12" label="关联单号">
						<template slot-scope="scope">
							<div style="color: #409EFF;cursor: pointer;" v-if="scope.row.OrderNo" @click="tagDoorLink(scope.row)">{{scope.row.OrderNo}}</div>
						</template>
					</el-table-column>
					<el-table-column :key="13" prop="AddTime" label="变更时间"></el-table-column>
					<el-table-column :key="14" prop="Remark" label="备注" width="350px"></el-table-column>
				</el-table>

				<div style="display:flex;justify-content:space-between;align-items:center;">
					<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
					</div>
					<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
					 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
					 :total="Total">
					</el-pagination>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		ScoresTotal,
		Scoreconfiglist, //积分管理列表
		ScoreDetaillist, //积分明细列表
		DeleteConfiginfo, // 删除积分配置
		enumList, //积分变更原因
	} from '@/api/TurnTomySelf'
	import config from '@/config/index'
	import buttonPermissions from '@/components/buttonPermissions';
	export default {
		components: {
			buttonPermissions
		},
		data() {
			return {
				ManageSendPoint: 0,
				ManageSpendPoint: 0,
				ManageBlockPoint: 0,
				
				isSearch: false,
				searchType: 0,
				searchTypeList: [{
						id: 0,
						value: '手机号'
					},{
						id: 1,
						value: '会员编码'
					},{
						id: 2,
						value: '微信昵称'
					},{
						id: 3,
						value: '姓名'
					}
				],
				
				listStateList: [{
						id: 0,
						value: '积分管理'
					},
					{
						id: 1,
						value: '积分明细'
					}
				],
				listState: '0',
				ScoreManageData: [],
				ScoredetailData: [],
				searchKey: '',
				detailoading: false,
				ChangeStateList: [{
						Id: 0,
						Name: '收入'
					}, {
						Id: 1,
						Name: '支出'
					}, {
						Id: 2,
						Name: '冻结'
					}
				],
				ChangeState: null,
				CauseList: [],
				// {
				// 		Id: 0,
				// 		Name: '日历签到'
				// 	}, {
				// 		Id: 1,
				// 		Name: '消费赠送积分'
				// 	}, {
				// 		Id: 2,
				// 		Name: '退款扣积分'
				// 	}, {
				// 		Id: 3,
				// 		Name: '满减送积分'
				// 	}, {
				// 		Id: 4,
				// 		Name: '积分获得'
				// 	}, {
				// 		Id: 5,
				// 		Name: '积分扣除'
				// 	}, {
				// 		Id: 6,
				// 		Name: '积分兑换'
				// 	},
				// 	{
				// 		Id: 7,
				// 		Name: '积分冻结'
				// 	}
				CauseId: null,
				currentPage: 1,
				pageSize: 20,
				Total: 1,
				detailAllpoint: 0,
				detailSendPoint: 0,
				detailBlockPoint: 0,
				detailSpendPoint: 0,
				loading: false,
				editVisable: false,
				currentTab: 0, //当前选择的tab
				timeValue: [],
				
				scoreLoading: false
			};
		},
		created() {
			this.getEmlist()
		},
		computed:{
			placeholder(){
				if (this.searchType === 0){
					return '请输入手机号'
				}
				if (this.searchType === 1){
					return '请输入会员编码'
				}
				if (this.searchType === 2){
					return '请输入微信昵称'
				}
				if (this.searchType === 3){
					return '请输入姓名'
				}
			}
		},
		beforeMount() {
			//初始化
			if (this.$route.query.listState) {
				
				var memberid = sessionStorage.getItem('pointsmanagermemberid');
				if (memberid != this.$route.query.memberId){
					this.memberId = this.$route.query.memberId;
					sessionStorage.setItem('pointsmanagermemberid', this.$route.query.memberId);
				}
				
				this.listState = this.$route.query.listState;
				
				this.getScoreTotal();
				this.currentPage = 1;
				this.getScoreDetaillist();
				
			} else {
				this.listState = '0'
				this.searchKey = ''
				this.getScoreTotal()
				//获取积分管理列表数据
				this.getScoreManagelist()
			}


		},
		beforeDestroy(){
			sessionStorage.setItem('pointsmanagermemberid', '');
		},
		methods: {
			async getEmlist() {
				try {
					let data = {
						EnumName: 'EnumMemberPointRecordType'
					}
					let result = await enumList(data)
					if (result.IsSuccess) {
						this.CauseList = result.Result
						this.CauseList =  this.CauseList.filter(item =>{
							return item.Value != 4 && item.Value!=5
						})
					}
				} catch (err) {

				} finally {

				}
			},
			//订单跳转
			tagDoorLink(record) {
				this.$router.push({
					path: '/order/orderDetailpage',
					query: {
						searchType: 0,
						Id: record.OrderNo
					}
				});
			},
			searchHandel() {
				
				this.isSearch = this.searchKey ? true : false;
				this.memberId = '';
				this.currentPage = 1
				this.getScoreTotal();
				this.getScoreDetaillist();
			},
			//顶部tab切换事件
			filterFun(tab, event) {
				//积分管理
				if (this.listState == '0') {
					// this.searchKey = ''
					// this.currentTab = 0
					this.getScoreTotal()
					this.getScoreManagelist()
				}
				//积分明细
				else {
					// this.currentTab = 1
					this.getScoreTotal()
					this.currentPage = 1
					this.getScoreDetaillist()

				}
			},
			//积分管理统计
			async getScoreTotal() {
				this.scoreLoading = true;
				this.ManageSendPoint = null;
				this.ManageSpendPoint = null;
				this.ManageBlockPoint = null;
				
				try {
					let data = {}
					if (this.listState == 0) {
						data.KeyWords = '';
						data.KeywordsType = '';
					} else {
						data.KeyWords = this.searchKey;
						data.KeywordsType = this.searchType;
					}
					let result = await ScoresTotal(data)
					this.ManageSendPoint = result.Result.SendPoint
					this.ManageSpendPoint = result.Result.SpendPoint
					this.ManageBlockPoint = result.Result.BlockPoint
					this.detailAllpoint = result.Result.AllPoint
					this.detailSendPoint = result.Result.SendPoint
					this.detailBlockPoint = result.Result.BlockPoint
					this.detailSpendPoint = result.Result.SpendPoint
					// console.log(result, '统计结果')
				}finally {
					this.scoreLoading = false;
				}
				
			},
			async getScoreManagelist() {
				this.loading = true
				try {
					let result = await Scoreconfiglist({})
					if (result.IsSuccess) {
						this.ScoreManageData = result.Result.map(v=>{
							// console.log(v)
							//推手销售授权礼包商品
							if(v.GivingType==3){
								let pointlist = v.PointGivingConfigIntervalList.map(x=>x.Point)
								let min = Math.min(...pointlist)
								let max = Math.max(...pointlist)
								v.Point = min==max?min:(min + '~' + max)
							}
							return v
						})
					}
				}finally {
					this.loading = false
				}
			},
			//积分明细列表
			async getScoreDetaillist() {
				this.detailoading = true
				try {

					let data = {
						KeywordsType: this.searchType,
						KeyWords: this.searchKey,
						MemberId: this.memberId,
						PointChangeType: this.ChangeState,
						PointChangeReason: this.CauseId,
						StartTime: this.timeValue && this.timeValue[0] ? this.timeValue[0] : '',
						EndTime: this.timeValue && this.timeValue[1] ? this.timeValue[1] : '',
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					let result = await ScoreDetaillist(data)

					if (result.IsSuccess) {
						this.ScoredetailData = result.Result.Results || [];
						this.Total = result.Result.Total;
					}
				} catch (err) {
					console.log(err)
				} finally {
					this.detailoading = false
				}
			},
			eidtGroup(record) {
				this.$router.push({
					path: '/Customer/pointsRulesEdit',
					query: {
						Id: record.Id ? record.Id : 0
					}
				})
			},
			deleteChose(row) {
				this.$confirm('是否确认删除该条积分规则？积分规则删除对已产生积分不影响？', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async () => {
					try {
						let result = await DeleteConfiginfo({
							Id: row.Id
						});
						if (result.IsSuccess) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '删除成功！'
							});
							this.getScoreManagelist();
						}
					} catch (err) {
						this.$message({
							showClose: true,
							type: 'error',
							message: result.Message
						});
					} finally {

					}
				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			saveChange(type) {
				this.editVisable = false
				//type为1是保存
				if (type == 1) {

				}

			},
			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getScoreDetaillist();
			},

			// 翻页
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getScoreDetaillist();
			},
			timeChange(time) {
				console.log(time)
				console.log(this.timeValue)
			}
		}
	}
</script>

<style lang="less" scoped>
</style>
